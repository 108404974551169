const routes = [
    //首页
    {
        path: '/',
        name: 'HomePage',
        meta: { china:'首 页',en:'Home' },
        component: () => import('@/views/HomePage.vue'),
    },
    //走进康庄
    {
        path: '/GoHome/Synopsis',
        name: 'GoHome',
        meta: { china:'走进兴新',en:'GoHome' },
        component: () => import('../views/GoHome/Index.vue'),
        children: [
            {
                path: '/GoHome/Synopsis',
                name: 'Synopsis',
                meta: { china:'公司简介',en:'Synopsis' },
                component: () => import('../views/GoHome/Synopsis.vue'),
            },
            {
                path: '/GoHome/Company',
                name: 'Company',
                meta: { china:'公司架构',en:'Company' },
                component: () => import('../views/GoHome/Company.vue'),
            },
            {
                path: '/GoHome/Honour',
                name: 'Honour',
                meta: { china:'荣誉资质',en:'Honour' },
                component: () => import('../views/GoHome/Honour.vue'),
            },
			{
			    path: '/GoHome/Corporate',
			    name: 'Corporate',
			    meta: { china:'企业宣传片',en:'Corporate' },
			    component: () => import('../views/GoHome/Corporate.vue'),
			},
			{
			    path: '/GoHome/Detail',
			    name: 'Corporate',
			    meta: { china:'企业宣传片详情',en:'Corporate' },
			    component: () => import('../views/GoHome/Detail.vue'),
			}
        ]
    },
    //产品中心
    {
        path: '/Services/Security',
        name: 'Services',
        meta: { china:'服务领域',en:'Services' },
        component: () => import('../views/Services/Index.vue'),
        children: [
            {
                path: '/Services/Security',
                name: 'Security',
                meta: { china:'安全评价',en:'Security' },
                component: () => import('../views/Services/Security.vue'),
            },
            {
                path: '/Services/Safety',
                name: 'Safety',
                meta: { china:'安全检测',en:'Safety' },
                component: () => import('../views/Services/Security.vue'),
            },
            {
                path: '/Services/Health',
                name: 'Health',
                meta: { china:'卫生服务',en:'Health' },
                component: () => import('../views/Services/Security.vue'),
            },
            {
                path: '/Services/Training',
                name: 'Training',
                meta: { china:'培训服务',en:'Training' },
                component: () => import('../views/Services/Security.vue'),
            }
        ]
    },
	//新闻中心
	{
	    path: '/News/Company',
	    name: "News",
	    meta: { china:'新闻中心',en:'News' },
	    component: () => import('../views/News/Index.vue'),
		children: [
			{
				path: '/News/Company',
				name: 'CompanyNews',
				meta: { china:'公司新闻',en:'CompanyNews' },
				component: () => import('../views/News/Company.vue'),
			},
			{
				path: '/News/Notice',
				name: 'NoticeNews',
				meta: { china:'最新公告',en:'NoticeNews' },
				component: () => import('../views/News/Notice.vue'),
			},
			{
				path: '/News/Safety',
				name: 'SafetyNews',
				meta: { china:'安全要闻',en:'SafetyNews' },
				component: () => import('../views/News/Safety.vue'),
			},
			{
				path: '/News/Policies',
				name: 'PoliciesNews',
				meta: { china:'政策法规',en:'PoliciesNews' },
				component: () => import('../views/News/Policies.vue'),
			},
			{
			    path: '/News/Detail',
			    name: 'NewsDetail',
			    meta: { china:'详情',en:'Group Video' },
			    component: () => import('../views/News/Detail.vue'),
			}
		]
	},
    //旗下公司
    {
        path: '/Culture/PartyBuilding',
        name: "Culture",
        meta: { china:'党建文化',en:'Culture' },
        component: () => import('../views/Culture/Index.vue'),
		children: [
			{
				path: '/Culture/PartyBuilding',
				name: 'PartyBuilding',
				meta: { china:'党建工作',en:'PartyBuilding' },
				component: () => import('../views/Culture/PartyBuilding.vue'),
			},
			{
				path: '/Culture/Alliance',
				name: 'Alliance',
				meta: { china:'群团工作',en:'Alliance' },
				component: () => import('../views/Culture/Alliance.vue'),
			},
			{
				path: '/Culture/Civilized',
				name: 'Civilized',
				meta: { china:'文明单位创新',en:'Civilized' },
				component: () => import('../views/Culture/Civilized.vue'),
			},
			{
			    path: '/Culture/Detail',
			    name: 'CultureDetail',
			    meta: { china:'详情',en:'Group Video' },
			    component: () => import('../views/Culture/Detail.vue'),
			}
		]
    },
    //技术实力
    {
        path: '/TechnicalStrength/Team',
        name: "TechnicalStrength",
        meta: { china:'技术实力',en:'TechnicalStrength' },
        component: () => import('../views/TechnicalStrength/Index.vue'),
		children: [   
			{
				path: '/TechnicalStrength/Team',
				name: 'Team',
				meta: { china:'团队力量',en:'Team' },
				component: () => import('../views/TechnicalStrength/Team.vue'),
			},
			{
				path: '/TechnicalStrength/Occupation',
				name: 'Occupation',
				meta: { china:'职业卫生实验室',en:'Occupation' },
				component: () => import('../views/TechnicalStrength/Team.vue'),
			},
			{
				path: '/TechnicalStrength/Equipment',
				name: 'Equipment',
				meta: { china:'设备检测实验室',en:'Equipment' },
				component: () => import('../views/TechnicalStrength/Team.vue'),
			},
			{
				path: '/TechnicalStrength/Technology',
				name: 'Technology',
				meta: { china:'科技研发',en:'Technology' },
				component: () => import('../views/TechnicalStrength/Technology.vue'),
			},
			{
				path: '/TechnicalStrength/GasDefense',
				name: 'GasDefense',
				meta: { china:'省防毒技术中心',en:'GasDefense' },
				component: () => import('../views/TechnicalStrength/GasDefense.vue'),
			},
			{
			    path: '/TechnicalStrength/Detail',
			    name: 'Technology',
			    meta: { china:'科技研发详情',en:'Technology' },
			    component: () => import('../views/TechnicalStrength/Detail.vue'),
			},
			{
			    path: '/TechnicalStrength/GasDefenseDetail',
			    name: 'GasDefense',
			    meta: { china:'省防毒技术中心详情',en:'Group Video' },
			    component: () => import('../views/TechnicalStrength/GasDefenseDetail.vue'),
			}
		]
    },
    //工程案例
    {
        path: "/DiscloseInformation/Organization",
        name: "DiscloseInformation", 
        meta: { china:'信息公开',en:'DiscloseInformation' },
        component: () => import('../views/DiscloseInformation/Index.vue'),
    	children: [
    		{
    			path: '/DiscloseInformation/Organization',
    			name: 'Organization',
    			meta: { china:'机构信息公开',en:'Organization' },
    			component: () => import('../views/DiscloseInformation/Organization.vue'),
    		},
    		{
    			path: '/DiscloseInformation/Project',
    			name: 'Project',
    			meta: { china:'项目信息公开',en:'Project' },
    			component: () => import('../views/DiscloseInformation/Project.vue'),
    		},
			{
			    path: '/DiscloseInformation/Detail',
			    name: 'DiscloseInformationDetail',
			    meta: { china:'详情',en:'Group Video' },
			    component: () => import('../views/DiscloseInformation/Detail.vue'),
			},
			{
			    path: '/DiscloseInformation/sbjcDetail',
			    name: 'DiscloseInformationDetail',
			    meta: { china:'详情',en:'Group Video' },
			    component: () => import('../views/DiscloseInformation/sbjcDetail.vue'),
			}
    	]
    },
    //联系我们
    {
        path: '/ConnectUs',
        name: "ConnectUs",
        meta: { china:'联系我们',en:'Contact us' },
        component: () => import('../views/ConnectUs/Index.vue'),
        children: []
    },
    //搜索
    {
        path: '/Search',
        name: "Search",
        meta: { title: "搜索" },
        component: () => import('../views/Search.vue'),
		children: [
			{
			   path: '/ConnectUs/Search',
			   name: 'Search',
			   meta: { china:'搜索',en:'Search' },
			   component: () => import('../views/Search.vue'),
			},
		]
    }
	
]

export default routes