import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		lang:'china',
		style:{
			transform: 'scale(0.6)'
		},
		titleDetail:''
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
