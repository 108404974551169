<template>
	<div id="app">
		<div class="home">
			<header class="ls-flex-center">
				<!-- 导航菜单 -->
				<div :style="$store.state.style" class="tabs">
					<div class="ls-flex h-100 ls-jc-center w-100">
						<div class="tabs_content ls-flex">
							<div @mousemove="childrenList = []" class="m-r-20 tabs_btn_logo">
								<router-link to="/">
									<!-- <img class="logo_img_p" :src="indexData.logo" alt=""> -->
									<div class="logo">
										<div class="logo-1 f-3546A0 f-bold">{{indexData.name || ''}}</div>
										<div class="logo-2 f-3546A0">Shanxi Xingxin Safety Production Technology Service Co.,Ltd</div>
									</div>
									
								</router-link>
							</div>
							<div class="tabs_btn ls-flex-flex window_tabs_btn">
								<ul class="w-100">
									<li class="hoverLi" @mousemove="tabsBtnMousemove(item)" v-for="(item, index) in lists" :key="index" :class="classes(item)">
										<router-link :to="item.path">{{item.meta[$store.state.lang]}}</router-link>
									</li>
									<li @mousemove="childrenList = []">
										<router-link to="/search"><img src="./assets/image/sousuo.png" style="width: 1.375rem;height: 1.375rem;" alt=""></router-link>
									</li>
								</ul>
							</div>
							<div class="phone_tabs_btn ls-flex-flex">
								<div class="w-100 h-100 ls-flex ls-al-center ls-jc-space-between">
									<img @click="show = !show" src="../src/assets/phone_menu.png" style="width: 20px;height: 20px;" alt="">
								</div>
							</div>
						</div>
					</div>
					<div v-if="childrenList.length > 0" class="tabs_box p-b-40 window_tabs_btn">
						<div class="ls-flex-center h-100">
							<div class="ls-flex tabs_box_box ls-jc-center">
								<div class="max-width ls-flex">
									<div class="ls-flex-col m-t-30 window_tabs_btn_left">
										<!-- <img :src="indexData.gzh_img" style="width: 6.4375rem;height: 6.4375rem;" alt="">
										<div class="ls-flex m-tb-20 ls-al-center">
											<img src="./assets/24gf-phoneLoudspeaker.png" style="width: 2rem;height: 2rem;" alt="">
											<span class="fs-14 f-999 m-l-10">{{indexData.fwtel || ''}}</span>
										</div>
										<div class="ls-flex ls-al-center">
											<img src="./assets/wz.png" style="width: 2rem;height: 2rem;" alt="">
											<span class="fs-14 f-999 m-l-10">{{indexData.address || ''}}</span>
										</div> -->
										<div class="fs-40 f-bold f-3546A0 m-b-20">
											{{childrenObj.china}}
										</div>
										<div class="fs-24 f-bold f-DFE0E1">
											{{childrenObj.en}}
										</div>
									</div>
									<div class="ls-flex-flex children ls-flex">
										<div class="children_item w-100">
											<div class="ls-flex ls-flex-rw w-100">
												<div v-if="!(im.path.indexOf('Detail') > -1)" class="ls-flex-col children_item_im" v-for="(im,ix) in childrenList" :key="ix">
													<router-link :to="im.path">
														<div class="ls-flex ls-al-center ls-jc-space-between m-b-15">
															<div class="ls-flex-col m-r-20 ls-flex-flex">
																<span class="fs-20 span_children_item_1 text-ellipsis-1">{{im.meta[$store.state.lang]}}</span>
															</div>
															<div class="img"></div>
														</div>
														<div class="children_item_line">
															<div class="children_item_line_div h-100"></div>
														</div>
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</header>

			<main class="bg-c-f9f9f9">
				<router-view></router-view>
			</main>

			<footer style="background-color: #000; overflow: hidden;">
				<!-- 相关 -->
				<div class="correlation ls-flex ls-jc-center" >
				  <!-- 相关链接 -->
					<div class="corr-content">
						<ul class="ov-hidden m-r-40" v-for="(item, index) in routes" :key="index">
							<template v-if="item.name != 'search' && item.name != 'subject' ">
								<h1 @click="changeRoute(item)">{{ item.meta[$store.state.lang] }}</h1>
								<span></span>
								<template>
									<li v-if="!(i.path.indexOf('Detail') > -1)" v-for="(i, x) in item.children" :key="index + '-' + x">
										<template v-if="i.name != 'Detail' || i.name != 'NewsDetail'">
											<router-link :to="i.children ? i.children[0].path : i.path" @click.native="toTop">{{i.meta[$store.state.lang]}}</router-link>
										</template>
									</li>
								</template>
							</template>
						</ul>
					</div>
					<div class="ls-flex m-t-40 QRCode">
						<div class="">
							<div class="ls-flex ls-al-center m-b-30">
								<img src="./assets/image/foot_dianhua.png" style="width: 1.1875rem;height: 1.0625rem;" alt="">
								<span class="f-fff m-l-20">{{indexData.phone || ''}}</span>
							</div>
							<div class="ls-flex ls-al-center m-b-30">
								<img src="./assets/image/foot_wangzhi.png" style="width: 1.3125rem;height: 1.3125rem;" alt="">
								<span class="f-fff m-l-20">{{indexData.website || ''}}</span>
							</div>
							<div class="ls-flex ls-al-center">
								<img src="./assets/image/foot_dizhi.png" style="width: 1.375rem;height: 1.375rem;" alt="">
								<span class="f-fff m-l-20" style="width: 210px;">{{indexData.site || ''}}</span>
							</div>
						</div>
						<div class="parting-line-B8DEFF m-lr-30"></div>
						<div class="ls-flex-col w-max-content ls-al-center">
							<img :src="indexData.qr" style="width: 6.4375rem;height: 6.4375rem;" alt="">
							<span class="f-fff m-t-20">{{$cetihuan.QRCode[$store.state.lang]}}</span>
						</div>
					</div>
				</div>

				<div class="bottom" :style="$store.state.style">
					<p>
						Copyright©  2016-2024 xingxin-group.com ALL right reserved.
					</p>
					<p class="m-l-10 ls-flex-center">
						<!-- <img class="m-r-10" src="./assets/beian.png" style="width: 20px;height: 20px;" alt=""> -->
						{{$cetihuan.beian[$store.state.lang]}}：<a href="https://beian.miit.gov.cn/#/Integrated/index"target="_Blank" style="color: #fff;">晋ICP备2023024467号</a>
					</p>
					<p class="m-l-10 ls-flex-center">
						<img class="m-r-10" src="./assets/beian.png" style="width: 20px;height: 20px;" alt="">
						<a href="https://beian.miit.gov.cn/#/Integrated/index"target="_Blank" style="color: #fff;">晋公网安备14010902000987号</a>
					</p>
					<!-- 琼ICP备2023008879号 -->
					<!-- 琼公网安备46900302000070 -->
					<p class="m-l-10">
						{{$cetihuan.jishu[$store.state.lang]}}：<a href="http://www.bjkhrs.com"target="_Blank">{{$cetihuan.khrs[$store.state.lang]}}</a>
					</p>
					<!-- <p>
						Copyright©  2022-2023{{$cetihuan.copyright[$store.state.lang]}}：{{indexData.company || ''}}    
						{{$cetihuan.beian[$store.state.lang]}}:<a href="https://beian.miit.gov.cn/#/Integrated/index"target="_Blank">{{indexData.beian || ''}}</a>
						{{$cetihuan.jishu[$store.state.lang]}}：<a href="http://www.bjkhrs.com"target="_Blank">科辉荣盛</a>
					</p> -->
				</div>
			</footer>
			<el-collapse-transition>
				<div class="home_popup ls-flex" v-show="show">
					<div class="ls-flex-flex"></div>
					<div class="correlation p-t-20">
					  <!-- 相关链接 -->
						<div class="corr-content">
							<ul v-for="(item, index) in lists" :key="index">
								<template v-if="item.name != 'search' && item.name != 'subject'">
									<div :class="item.name == propsName ? 'select_prop_name' : ''" class="ls-flex ls-al-center ls-jc-space-between p-lr-20">
										<h1 @click="changeRoute(item)"> {{ item.meta[$store.state.lang] }}</h1>
										<img v-if="item.name == propsName" src="./assets/home-popup-2.png" style="width: 13px;height: 7px;" alt="">
										<img v-else src="./assets/home-popup-1.png" style="width: 7px;height: 13px;" alt="">
									</div>
									<template>
										<li class="home_popup-li p-l-30 p-r-20" v-if="item.name == propsName" v-for="(i, x) in item.children" :key="index + '-' + x">
											<template v-if="!(i.path.indexOf('Detail') > -1)">
												<router-link :class="childrenName == i.name ? 'bg-c-3546A0' : ''" class="ls-flex ls-al-center ls-jc-space-between" :to="i.children ? i.children[0].path : i.path" @click.native="childrenSelect(i)">
													{{i.meta[$store.state.lang]}}
													<img src="./assets/home-popup-3.png" style="width: 4px;height: 10px;" alt="">
												</router-link>
											</template>
										</li>
									</template>
								</template>
							</ul>
						</div>
					</div>
				</div>
			</el-collapse-transition>
		</div>
	</div>
</template>

<script>
import routes from "./router/routes";
import { getIndexData } from "@/api/api.js";
// import { getZqxgsListChinaData, getZqxgsListEnData, getCpListChinaData, getCpListEnData } from "@/api/apiApi.js";
export default {
	data() {
		return {
			lists: [],
			routes: [],
			name:'',
			show:true,
			branchCompanyList:[],
			branchCompanyListEn:[],
			cpList:[],
			cpListChina:[],
			cpListEn:[],
			indexData:{},
			childrenList:[],
			childrenObj:{},
			propsName:'HomePage',
			childrenName:'',
			style:{
				
			},
		};
	},

	created() {
		let _self = this;
		
		if (window.innerWidth > 1199 && window.innerWidth < 1501) {
			let num1 = window.innerWidth / 1400;
			_self.$store.state.style = {
				transform: 'scale(' + num1 + ')'
			}
		}else{
			_self.$store.state.style = {}
		}
		
		this.lists = routes.slice(0, 8);
		this.routes = routes.slice(0, 8);
		this.getData();
		window.addEventListener('resize', function(evt){
			if (window.innerWidth > 1199 && window.innerWidth < 1501) {
				let num = window.innerWidth / 1400;
				_self.$store.state.style = {
					transform: 'scale(' + num + ')'
				}
			}else{
				_self.$store.state.style = {}
			}
			
		});
	},
	// 监听路由变化
	watch: {
		$route: {
			handler: function (val, oldVal) {
				this.name = val.name;
				this.show = false;
				console.log(val);
			},
			deep: true,
		}
	},
	methods: {
		// 判断是否有子路由
		classes(el) {
			//判断子路由是否有匹配项
			if (el.name == this.name) {
				return el.name == this.name ? "active" : "";
			} else if (el.children) {
				return el.children.some((item) => {
					if (item.children) {
						return item.children.some((i) => {
							return i.name === this.name;
						});
					} else {
						return item.name === this.name;
					}
				})
				? "active"
				: "";
			} else {
				return el.name === this.name ? "active" : "";
			}
		},
		// 返回顶部
		toTop() {
			document.documentElement.scrollTop = 0;
			this.show = false;
		},
		// 跳转首页
		toHome() {
			this.$router.push("/");
			this.toTop();
		},
		// 获取数据
		getData() {
			getIndexData().then((res) => {
				this.indexData = res.data;
			});
		},
		// 搜索
		search() {
			console.log(this.value);
			this.$router.push(`/search?value=${this.value}`);
		},
		changeRoute(item) {
			console.log(item);
			if (item.name == 'ConnectUs') {
				this.$router.push('/ConnectUs');
				this.toTop();
			} else{
				if (item.children) {
					this.propsName = item.name;
					this.childrenName = item.children[0].name;
					this.$router.push(item.children[0].path);
				} else {
					this.$router.push('/');
					this.toTop();
				}
			}
			
		},
		childrenSelect(i){
			this.childrenName = i.name;
		},
		tabsBtnMousemove(im){
			let _self = this;
			this.childrenList = [];
			if (im.children) {
				_self.childrenObj = im.meta;
				this.childrenList = im.children;
			}
		}
	},
	mounted() {
		
	},
};
</script>

<style lang="scss">
	@font-face {
		font-family: 'myfont';
		src: url('./assets/fonts/MSYH.TTF');
	}
	@font-face {
		font-family: 'myfontbd';
		src: url('./assets/fonts/MSYHBD.TTF');
	}
a {
  text-decoration: none;
  // color: #ffffff;
}

a:link {
  color: #fff;
}

* {
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-family: 'myfont' , sans-serif;
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		font-size: 12px !important;
	}
	@media screen and (max-width: 1200px) {
		
	}
}

html,
body {
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
	overflow: visible;
	@media screen and (max-width: 1200px) {
		overflow: visible !important;
		font-size: 12px !important;
	}
}
body {
    transition: transform 0.5s ease-in-out;
  }
#app {
	width: 100%;
	height: auto;
	.w-max-content{
		width: max-content;
	}
	.ls-flex{
		display: flex;
	}
	.ls-flex-col{
		display: flex;
		flex-direction: column;
	}
	.ls-flex-center{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ls-flex-col-center{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.ls-al-center{
		align-items: center;
	}
	.ls-al-end{
		align-items: flex-end;
	}
	.ls-jc-center{
		justify-content: center;
	}
	.ls-jc-space-between{
		justify-content: space-between;
	}
	.ls-jc-space-evenly{
		justify-content: space-evenly;
	}
	.ls-flex-flex{
		flex: 1;
	}
	.ls-flex-rw{
		flex-flow: row wrap;
	}
	.f-fff{
		color: #fff !important;
	}
	.f-333{
		color: #333 !important;
	}
	.f-666{
		color: #666 !important;
	}
	.f-999{
		color: #999 !important;
	}
	.f-BCBFD7{
		color: #BCBFD7 !important;
	}
	.f-3546A0{
		color: #3546A0 !important;
	}
	.f-DFE0E1{
		color: #DFE0E1 !important;
	}
	.ta-center{
		text-align: center;
	}
	.ta-justify{
		text-align: justify;
	}
	.bg-c-f7f7f7{
		background-color: #F7F7F7;
	}
	.bg-c-f5f5f5{
		background-color: #f5f5f5;
	}
	.bg-c-f9f9f9{
		background-color: #f9f9f9;
	}
	.bg-c-3546A0{
		background-color: #3546A0;
	}
	.bg-c-fff{
		background-color: #fff;
	}
	.bg-c-ECECEC{
		background-color: #ECECEC;
	}
	.bg-c-f2f2f2{
		background-color: #f2f2f2;
	}
	.bg-c-f8f8f8{
		background-color: #f8f8f8;
	}
	.f-bold{
		font-weight: bold;
		font-family: 'myfontbd' , sans-serif;
	}
	/* // 字体 */
	.fs-14{
		font-size: 0.875rem !important;
		
	}
	.fs-16{
		font-size: 1rem !important;
	}
	.fs-18{
		font-size: 1.125rem !important;
	}
	.fs-20{
		font-size: 1.25rem !important;
	}
	.fs-22{
		font-size: 1.375rem !important;
	}
	.fs-24{
		font-size: 1.5rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 0.875rem !important;
		}
	}
	.fs-28{
		font-size: 1.75rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.125rem !important;
		}
	}
	.fs-26{
		font-size: 1.625rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1rem !important;
		}
	}
	.fs-30{
		font-size: 1.875rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.25rem !important;
		}
	}
	.fs-32{
		font-size: 2rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.375rem !important;
		}
	}
	.fs-34{
		font-size: 2.125rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.5rem !important;
		}
	}
	.fs-36{
		font-size: 2.25rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.625rem !important;
		}
	}
	.fs-38{
		font-size: 2.375rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.75rem !important;
		}
	}
	.fs-40{
		font-size: 2.5rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 1.875rem !important;
		}
	}
	.fs-42{
		font-size: 2.625rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 2rem !important;
		}
	}
	.fs-46{
		font-size: 2.875rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 2.25rem !important;
		}
	}
	.fs-60{
		font-size: 3.75rem !important;
		@media screen and (max-width: 1200px) {
			font-size: 3.125rem !important;
		}
	}
	@media screen and (max-width: 1200px) {
		
	}
	.l-h-24{
		line-height: 1.5rem;
	}
	.l-h-28{
		line-height: 1.75rem;
	}
	.l-h-30{
		line-height: 1.875rem;
	}
	.l-h-34{
		line-height: 2.125rem;
	}
	.l-h-36{
		line-height: 2.25rem;
	}
	.l-h-38{
		line-height: 2.375rem;
	}
	.l-h-40{
		line-height: 2.5rem;
	}
	.f-family{
		// font-family: Microsoft YaHei;
		font-style: italic;
	}
	.f-family-myfont{
		font-size: 22px;
		font-family: 'myfont' , sans-serif;
	}
	/* // margin上下 */
	.m-tb-10{
		margin: 0.625rem 0;
	}
	.m-tb-20{
		margin: 1.25rem 0;
	}
	.m-tb-30{
		margin: 1.875rem 0;
	}
	.m-tb-40{
		margin: 2.5rem 0;
	}
	.m-tb-50{
		margin: 3.125rem 0;
	}
	/* // margin左右 */
	.m-lr-10{
		margin: 0 0.625rem;
	}
	.m-lr-20{
		margin: 0 1.25rem;
	}
	.m-lr-30{
		margin: 0 1.875rem;
	}
	.m-lr-40{
		margin: 0 2.5rem;
	}
	.m-lr-50{
		margin: 0 3.125rem;
	}
	/* // padding上下 */
	.p-tb-10{
		padding: 0.625rem 0;
	}
	.p-tb-15{
		padding: 0.9375rem 0;
	}
	.p-tb-20{
		padding: 1.25rem 0;
		@media screen and (max-width: 1200px) {
			padding: 0.625rem 0 !important;
		}
	}
	.p-tb-30{
		padding: 1.875rem 0;
		@media screen and (max-width: 1200px) {
			padding: 0.9375rem 0 !important;
		}
	}
	.p-tb-40{
		padding: 2.5rem 0;
		@media screen and (max-width: 1200px) {
			padding: 1.25rem 0 !important;
		}
	}
	.p-tb-50{
		padding: 3.125rem 0;
	}
	/* // padding左右 */
	.p-lr-10{
		padding: 0 0.625rem;
	}
	.p-lr-15{
		padding: 0 0.9375rem;
	}
	.p-lr-20{
		padding: 0 1.25rem;
	}
	.p-lr-30{
		padding: 0 1.875rem;
	}
	.p-lr-40{
		padding: 0 2.5rem;
	}
	.p-lr-50{
		padding: 0 3.125rem;
	}
	/* // margin-left */
	.m-l-10{
		margin-left: 0.625rem;
	}
	.m-l-15{
		margin-left: 0.9375rem;
	}
	.m-l-20{
		margin-left: 1.25rem;
	}
	.m-l-30{
		margin-left: 1.875rem;
	}
	.m-l-40{
		margin-left: 2.5rem;
	}
	.m-l-50{
		margin-left: 3.125rem;
	}
	.m-l-80{
		margin-left: 5rem;
	}
	/* // margin-right */
	.m-r-10{
		margin-right: 0.625rem;
	}
	.m-r-15{
		margin-right: 0.9375rem;
	}
	.m-r-20{
		margin-right: 1.25rem;
	}
	.m-r-25{
		margin-right: 1.5625rem;
	}
	.m-r-30{
		margin-right: 1.875rem;
	}
	.m-r-40{
		margin-right: 2.5rem;
	}
	/* // margin-top */
	.m-t-10{
		margin-top: 0.625rem;
	}
	.m-t-15{
		margin-top: 0.9375rem;
	}
	.m-t-20{
		margin-top: 1.25rem;
	}
	.m-t-25{
		margin-top: 1.5625rem;
	}
	.m-t-30{
		margin-top: 1.875rem;
	}
	.m-t-35{
		margin-top: 35px;
	}
	.m-t-40{
		margin-top: 2.5rem;
	}
	.m-t-50{
		margin-top: 3.125rem;
	}
	/* // margin-top */
	.m-b-10{
		margin-bottom: 0.625rem;
	}
	.m-b-15{
		margin-bottom: 0.9375rem;
	}
	.m-b-20{
		margin-bottom: 1.25rem;
	}
	.m-b-25{
		margin-bottom: 1.5625rem;
	}
	.m-b-30{
		margin-bottom: 1.875rem;
	}
	.m-b-40{
		margin-bottom: 2.5rem;
	}
	.m-b-50{
		margin-bottom: 3.125rem;
	}
	/* // padding-left */
	.p-l-10{
		padding-left: 0.625rem;
	}
	.p-l-20{
		padding-left: 1.25rem;
	}
	.p-l-30{
		padding-left: 1.875rem;
	}
	.p-l-40{
		padding-left: 2.5rem;
	}
	.p-l-60{
		padding-left: 3.75rem;
	}
	/* // padding-right */
	.p-r-10{
		padding-right: 0.625rem;
	}
	.p-r-20{
		padding-right: 1.25rem;
	}
	.p-r-30{
		padding-right: 1.875rem;
	}
	.p-r-40{
		padding-right: 2.5rem;
	}
	/* // padding-top */
	.p-t-10{
		padding-top: 0.625rem;
	}
	.p-t-20{
		padding-top: 1.25rem;
	}
	.p-t-30{
		padding-top: 1.875rem;
	}
	.p-t-40{
		padding-top: 2.5rem;
	}
	/* // padding-bottom */
	.p-b-10{
		padding-bottom: 0.625rem;
	}
	.p-b-20{
		padding-bottom: 1.25rem;
	}
	.p-b-30{
		padding-bottom: 1.875rem;
	}
	.p-b-40{
		padding-bottom: 2.5rem;
	}
	/* // padding */
	.p-10{
		padding: 0.625rem;
	}
	.p-15{
		padding: 0.9375rem;
	}
	.p-20{
		padding: 1.25rem;
	}
	.p-30{
		padding: 1.875rem;
		@media screen and (max-width: 1200px) {
			padding: 0.9375rem
		}
	}
	.p-40{
		padding: 2.5rem;
	}
	.b-r-10{
		border-radius: 0.625rem;
	}
	.b-r-20{
		border-radius: 1.25rem;
	}
	.b-r-30{
		border-radius: 1.875rem;
	}
	.b-r-50{
		border-radius: 50%;
	}
	.parting-line-CCD4FF{
		height: 1px;
		background-color: #CCD4FF;
	}
	.parting-line-f5f5f5{
		height: 1px;
		background-color: #f5f5f5;
	}
	.parting-line-B8DEFF{
		width: 1px;
		height: 135px;
		background-color: #B8DEFF;
	}
	.text-ellipsis-1{
		overflow: hidden;
		-webkit-line-clamp: 1;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.text-ellipsis-2{
		overflow: hidden;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.text-ellipsis-3{
		overflow: hidden;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.ov-hidden{
		overflow: hidden;
	}
	.max-width{
		@media screen and (min-width: 1200px) {
			width: 1200px;
		}
		@media screen and (max-width: 1198px) and (min-width:900px) {
			width: 900px;
		}
		@media screen and (max-width: 1200px) {
			width: 90%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15px;
		}
	}
	.w-100{
		width: 100%;
	}
	.w-max-content{
		width: max-content;
	}
	.h-100{
		height: 100%;
	}
	.h-0{
		height: 0;
	}
	.cursor-pointer{
		cursor: pointer;
	}
}

main {
	// padding-top: 6.5rem;
	height: auto;
	width: 100%;
	background: #fff;
	

	@media screen and (max-width: 1200px) {
		width: 100% !important;
		background: #f3f3f3;
		padding-bottom: 0 !important;
		padding-top: 3.5rem !important;
	}
}
.BreadcrumbVue{
	@media screen and (max-width: 1200px) {
		display: none !important;
	}
}
.home {
	width: 100%;
	height: auto;

	@media screen and (max-width: 1200px) {
		background: rgb(243, 243, 243) !important;
	}
}

.logo1 {
	display: none;

	@media screen and (max-width: 1200px) {
		display: block;
		height: 3.75rem !important;
	}
}

header {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999999;
	background-color: #fff;
	@media screen and (max-width: 1200px) {
		// position: relative;
		z-index: 200000000;
	}
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
.tabs:hover .tabs_box{
	display: block;
}
.tabs {
	width: 100%;
	height: 6.5rem;
	@media screen and (max-width: 1200px) {
		height: 3.5rem !important;
	}
	.logo{
		.logo-1{
			font-size: 24px;
			@media screen and (max-width: 1200px) {
				font-size: 12px;
			}
		}
		.logo-2{
			font-size: 14px;
			@media screen and (max-width: 1200px) {
				font-size: 7px;
			}
		}
	}
	
	.window_tabs_btn{
		@media screen and (max-width: 1200px) {
			display: none !important;
		}
	}
	.phone_tabs_btn{
		display: none;
	}
	.phone_tabs_btn{
		@media screen and (max-width: 1200px) {
			display: block !important;
		}
	}
	.tabs_content{
		width: 1400px;
		@media screen and (max-width: 1200px) {
			padding: 0 20px;
		}
	}
	.tabs_box{
		width: 100%;
		height: 240px;
		position: relative;
		display: none;
		z-index: 999;
		.tabs_box_box{
			width: 1400px;
			height: 100%;
			background-color: #fff;
			// background-image: url('./assets/header_bg.png');
			background-repeat: no-repeat;
			background-size: 30%;
			background-position: bottom left;
			border-top: 1px solid #f7f7f7;
		}
		.window_tabs_btn_left{
			padding-top: 40px;
		}
		.children{
			position: relative;
			top: 40px;
			padding-top: 5rem;
			.children_item{
				position: absolute;
				top: 0;
				left: 0;
				.children_item_im{
					width: 25%;
					margin-left: 5.3%;
					margin-bottom: 3.75rem;
				}
				.img{
					width: 10px;
					height: 17px;
					background-image: url('./assets/header_more_2.png');
					background-repeat: no-repeat;
					background-size: 100%;
				}
				.children_item_im:hover .img{
					background-image: url('./assets/header_more_1.png');
				}
				.children_item_line{
					height: 1px;
					background: #F2F2F2;
					position: relative;
					.children_item_line_div{
						width: 20%;
						position: absolute;
						top: 0;
						left: 0;
						background: #3546A0;
						transition: all 0.5s 0s;
					}
				}
				.children_item_im:hover .children_item_line_div{
					width:100%;
				}
				.span_children_item_1{
					color: #666;
				}
				.children_item_im:hover .span_children_item_1{
					color: #3546A0;
				}
			}
		}
		
	}
	.tabs_btn_logo{
		@media screen and (max-width: 1200px) {
			width: 90%;
		}
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		img{
			width: 100%;
			height: auto;
		}
	}
	.tabs_btn {
		// width: 945px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		img{
			width: 100%;
			height: fit-content;
		}
		ul {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-around;
			height: 100%;

			.active {

				a {
					color:#fff;
					background-color: #3546A0;
				}
			}
			.hoverLi:hover a{
				color:#fff;
				background-color: #3546A0;
			}
			.hoverLi:hover .lang{
				color:#fff;
				background-color: #3546A0;
			}
			li {
				list-style: none;
				width: 7.5rem;
				height: 100%;
				font-size: 1.375rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333;
				line-height: 1.5625rem;
				display: flex;
				align-items: center;
				justify-content: space-around;

				a {
					width: 7.5rem;
					height: 1.5625rem;
					border-radius: 1.5625rem;
					font-size: 1.375rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333;
					line-height: 1.5625rem;
					display: block;
					padding: 0.375rem 0;
					font-weight: bold;
					border-bottom: transparent 2px solid;
					text-align: center;
				}
				.lang{
					width: 7.5rem;
					height: 1.5625rem;
					border-radius: 1.5625rem;
					font-size: 1.375rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333;
					line-height: 1.5625rem;
					padding: 0.375rem 0;
					font-weight: bold;
					border-bottom: transparent 2px solid;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
}

footer {
  // margin-top: 6.5625rem;
	.QRCode{
		margin-left: 1.875rem;
	}
	@media screen and (max-width: 1200px) {

		.QRCode{
			font-size: 0.75rem;
			margin-left: 0 !important;
			transform: scale(0.9);
		}



		.bottom {
			height: auto !important;
			font-size: 0.875rem !important;
			text-align: center !important;
			a{
				color: #fff;
			}
		}
	}
	@media screen and (min-width: 1200px) {
		
	}
	.correlation {
		width: 100%;
		// height: 49.5rem;
		background-color: #000;
		padding-bottom: 3.125rem;
	
		.corr-content {
			// width: calc(100% - 28.375rem);
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// margin: 0 auto;
	
			@media screen and (max-width: 1200px) {
				display: none !important;
			}
	
			.index {
				display: none;
			}
	
			ul {
				width: auto;
				list-style: none;
				color: #ffffff;
				margin-top: 2.25rem;
				flex-shrink: 0;
	
				h1 {
				  font-size: 1.25rem;
				  font-weight: bold;
				  line-height: 1.75rem;
				  cursor: pointer;
				}
	
				span {
				  display: block;
				  background: #fff;
				  width: 2.25rem;
				  height: 0.125rem;
				  margin-top: 0.1875rem;
				}
	
				li {
				  font-size: 1rem;
				  font-weight: 400;
				  color: #d1d5d6;
				  line-height: 2.1875rem;
	
				  a {
					color: #d1d5d6;
				  }
				}
			}
		}
	}



	.bottom {
		width: 100%;
		height: 4.6875rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;

		@media screen and (max-width: 1200px) {
			padding: 10px 0 60px 0;
			flex-direction: column;
			p {
				font-size: 0.75rem !important;
				a{
					font-size: 0.75rem !important;
				}
			}
		}

		p {
			width: auto;
			height: auto;
			font-size: 1.125rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 1.5625rem;
			a{
				font-size: 1.125rem;
			}
		}
	}
}

.home_popup {
  width: calc(100%) !important;
  height: calc(100%) !important;
  position: fixed;
  z-index: 199999999;
  top: 3.5rem;
  left: 0;
  transition: all 0s;
  background-color: rgba(0, 0, 0, 0.78);

  @media screen and (min-width: 768px) {
    display: none !important;
  }

  h1 {
    color: #b4a48a;

    a {
      font-size: 1.25rem !important;

      color: #b4a48a;
    }
  }

	.correlation {
		width: 80%;
		height: 100%;
		overflow-y: scroll;
		background-color: #3546A0;

		.corr-content {
			ul {
				// display: flex;
				flex-wrap: wrap;
				list-style: none;

				h1 {
					width: max-content !important;
					height: 1.875rem;
					font-size: 1rem;
					flex-shrink: 0;
					color: #fff;
					text-align: center;
					line-height: 1.875rem;
					border-radius: 0.9375rem;
					margin: 0.9375rem 0;
				}
				.select_prop_name{
					background-color: rgba(0, 0, 0, 0.2);
					
				}
				li {
					
					a{
						height: 1.875rem;
						padding: 15px 0 5px 0;
						color: #fff;
						border-bottom: 1px solid #fff;
					}
				}
			}
		}
	}
	.lang{
		color: #fff;
		padding: 15px 0;
	}
}

// 进度条颜色
// #nprogress .bar {
//   background: blue !important; //这里可以随便写颜色
// }
</style>
