
export default {
	toUpperCase(str) {
		return str.toUpperCase()
	},
	// v-html 图片宽100%
	formatRichText(html) { //控制小程序中图片大小
		let newContent = ''
		if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
			newContent = html.replace(/<img[^>]*>/gi,
			function(match) {
				match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			newContent = newContent.replace(/style="[^"]+"/gi,
			function(match) {
				match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
				return match;
			});
			// newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10px auto;"');
			return newContent;
		}
	},
	formatRichTextSpan(html) { //控制小程序中图片大小
		let newContent = ''
		// if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
		// 	newContent = html.replace(/<span[^>]*>/gi,
		// 	function(match) {
		// 		match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
		// 		match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
		// 		match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
		// 		match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
		// 		return match;
		// 	});
		// 	newContent = newContent.replace(/style="[^"]+"/gi,
		// 	function(match) {
		// 		match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
		// 		return match;
		// 	});
		// 	// newContent = newContent.replace(/<br[^>]*\/>/gi, '');
		// 	newContent = newContent.replace(/\<span/gi, '<span class="f-family-myfont"');
		// 	return newContent;
		// }
		return html;
	},
	formatRichTextStrong(html) { //控制小程序中图片大小
		let newContent = ''
		if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
			newContent = html.replace(/<strong[^>]*>/gi,
			function(match) {
				match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			// newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<strong/gi, '<strong class="fs-22"');
			return newContent;
		}
		// return html;
	}
}