import request from '@/utils/request';
//网站配置数据
export function getIndexData() {
    return request({
        url: 'index/company',
        method: 'get',
        
    });
}
// 友情链接
export function getfrisrc() {
    return request({
        url: 'index/xxfrisrc',
        method: 'get',
        
    });
}
//轮播图
export function getIndexAd(params) {
    return request({
        url: 'index/banner',
        method: 'get',
        params: params
    });
}
// 服务领域分类列表
export function getfwlyTypeList() {
    return request({
        url: 'New_type_list/xxfwlyTypeList',
        method: 'get'
    });
}
// 服务领域列表
export function getfwlyList(params) {
    return request({
        url: 'New_list/xxfwlyList',
        method: 'get',
        params: params
    });
}
// 服务领域详情
export function getfwlyInfo(params) {
    return request({
        url: 'New_info/xxfwlyInfo',
        method: 'get',
        params: params
    });
}
// 新闻中心分类列表
export function getnewsTypeList(params) {
    return request({
        url: 'New_type_list/xxnewsTypeList',
        method: 'get',
		params: params
    });
}
// 新闻中心列表
export function getNewsList(params) {
    return request({
        url: 'New_list/xxnewsList',
        method: 'get',
        params: params
    });
}
// 新闻中心详情
export function getNewsInfo(params) {
    return request({
        url: 'New_info/xxnewsInfo',
        method: 'get',
        params: params
    });
}
// 荣誉资质分类列表
export function getryzzTypeList() {
    return request({
        url: 'New_type_list/xxryzzTypeList',
        method: 'get'
    });
}
// 荣誉资质列表
export function getryzzList(params) {
    return request({
        url: 'New_list/xxryzzList',
        method: 'get',
        params: params
    });
}
// 企业宣传片
export function getqyxcpList(params) {
    return request({
        url: 'New_list/xxqyxcpList',
        method: 'get',
        params: params
    });
}
// 企业宣传片详情
export function getqyxcpInfo(params) {
    return request({
        url: 'New_info/xxqyxcpInfo',
        method: 'get',
        params: params
    });
}
// 党建文化分类列表
export function getdjwhTypeList() {
    return request({
        url: 'New_type_list/xxdjwhTypeList',
        method: 'get'
    });
}
// 荣誉资质列表
export function getdjwhList(params) {
    return request({
        url: 'New_list/xxdjwhList',
        method: 'get',
        params: params
    });
}
// 党建文化详情
export function getdjwhInfo(params) {
    return request({
        url: 'New_info/xxdjwhInfo',
        method: 'get',
        params: params
    });
}
// 城市应知应会列表
export function getcsyzyhList(params) {
    return request({
        url: 'New_list/xxcsyzyhList',
        method: 'get',
        params: params
    });
}
// 信息公开分类列表
export function getxsjlTypeList() {
    return request({
        url: 'New_type_list/xsjlTypeList',
        method: 'get'
    });
}
// 信息公开列表
export function getxxgkList(params) {
    return request({
        url: 'New_list/xxxxgkList',
        method: 'get',
        params: params
    });
}
// 信息公开详情
export function getxxgkInfo(params) {
    return request({
        url: 'New_info/xxxxgkInfo',
        method: 'get',
        params: params
    });
}
// 设备检测列表
export function getsbjcList(params) {
    return request({
        url: 'New_list/xxxxgk_sbjcList',
        method: 'get',
        params: params
    });
}
// 设备检测详情
export function getsbjcInfo(params) {
    return request({
        url: 'New_info/xxxxgk_sbjcInfo',
        method: 'get',
        params: params
    });
}
// 技术实力分类列表
export function getjsslTypeList() {
    return request({
        url: 'New_type_list/xxjsslTypeList',
        method: 'get'
    });
}
// 技术实力列表
export function getjsslList(params) {
    return request({
        url: 'New_list/xxjssl',
        method: 'get',
        params: params
    });
}
// 科研成果列表
export function getkycgList(params) {
    return request({
        url: 'New_list/xxkycgList',
        method: 'get',
        params: params
    });
}
// 科研成果详情
export function getkycgInfo(params) {
    return request({
        url: 'New_info/xxkycgInfo',
        method: 'get',
        params: params
    });
}
// 科研项目列表
export function getkyxmList(params) {
    return request({
        url: 'New_list/xxkyxmList',
        method: 'get',
        params: params
    });
}
// 科研项目详情
export function getkyxmInfo(params) {
    return request({
        url: 'New_info/xxkyxmInfo',
        method: 'get',
        params: params
    });
}
// 省防毒中心简介
export function getsfdzxjjInfo() {
    return request({
        url: 'New_list/xxsfdzxjj',
        method: 'get'
    });
}
// 省防毒中心治理列表
export function getsfdzxzlList(params) {
    return request({
        url: 'New_list/xxsfdzxzlList',
        method: 'get',
        params: params
    });
}
// 省防毒中心治理详情
export function getsfdzxzlInfo(params) {
    return request({
        url: 'New_info/xxsfdzxzlInfo',
        method: 'get',
        params: params
    });
}











//首页数据
//关于我们
export function getAboutUsData() {
    return request({
        url: 'index/company',
        method: 'get'
    });
}
//产品中心
export function getCpClassData() {
    return request({
        url: 'index/cpClass',
        method: 'post'
    });
}
//产品描述
export function getCpmsData() {
    return request({
        url: 'index/cpms',
        method: 'post'
    });
}
//新闻数据
export function getNewsData(type) {
    return request({
        url: 'index/news',
        method: 'post',
		params: {
		    classname:type
		}
    });
}
//工程案例
export function getGcalData() {
    return request({
        url: 'index/gcal',
        method: 'post'
    });
}
//科技成果
export function getKjcgData() {
    return request({
        url: 'index/kjcg',
        method: 'post'
    });
}
//企业文化
export function getIndexQywhData() {
    return request({
        url: 'index/qywh',
        method: 'post'
    });
}
//走进康庄
//企业简介
export function getJianjieData() {
    return request({
        url: 'go/jianjie',
        method: 'post'
    });
}
//企业文化
export function getQywhData() {
    return request({
        url: 'go/qywh',
        method: 'post'
    });
}
//荣誉资质
export function getRyzzData(page) {
    return request({
        url: 'go/ryzz',
        method: 'post',
		params: {
		    page:page
		}
    });
}
//企业宣传片
export function getQyxcpData(page) {
    return request({
        url: 'go/qyxcp',
        method: 'post',
		params: {
		    page:page
		}
    });
}
//产品中心
//菜单
export function getCpListData() {
    return request({
        url: 'cp/cpList',
        method: 'post'
    });
}
// 详情
export function getCpCpInfoData(id) {
    return request({
        url: 'cp/cpInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//工程案例
export function getGcalListData(params) {
    return request({
        url: 'gcal/gcalList',
        method: 'post',
		params: params
    });
}
//工程案例详情
export function getCpInfoData(id) {
    return request({
        url: 'gcal/cpInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//旗下公司
export function getZqxgsListData() {
    return request({
        url: 'index/zqxgsList',
        method: 'post'
    });
}
//旗下公司详情
export function getZqxgsInfoData(id) {
    return request({
        url: 'index/zqxgsInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
//技术实力
//研发设计
export function getYfsjListData() {
    return request({
        url: 'index/yfsjList',
        method: 'post'
    });
}
//科技成果
export function getKjcgListData(page) {
    return request({
        url: 'index/kjcgList',
        method: 'post',
		params: {
		    page:page
		}
    });
}
// 新闻中心
export function getNewsListData(page,classname,p) {
    return request({
        url: 'index/newsList',
        method: 'post',
		params: {
		    page:page,
			classname:classname,
			p:p
		}
    });
}
//新闻详情
export function getNewsInfoData(id) {
    return request({
        url: 'index/newsInfo',
        method: 'post',
		params: {
		    id:id
		}
    });
}
// 留言
export function getAddLiuyanData(params) {
    return request({
        url: 'index/addLiuyan',
        method: 'post',
		params: params
    });
}